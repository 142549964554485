var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-space",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "基本信息",
                        bordered: false,
                      },
                    },
                    [
                      _c(
                        "a-descriptions",
                        { attrs: { column: 4 } },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "Redis版本" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cache.info.redis_version)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "运行模式" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cache.info.redis_mode ==
                                          "standalone"
                                          ? "单机"
                                          : "集群"
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "端口" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.cache.info.tcp_port)),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "客户端数" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cache.info.connected_clients)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "运行时间(天)" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cache.info.uptime_in_days)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "使用内存" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cache.info.used_memory_human)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "使用CPU" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        parseFloat(
                                          _vm.cache.info.used_cpu_user_children
                                        ).toFixed(2)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "内存配置" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cache.info.maxmemory_human)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "AOF是否开启" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cache.info.aof_enabled == "0"
                                          ? "否"
                                          : "是"
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "RDB是否成功" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cache.info.rdb_last_bgsave_status
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "Key数量" } },
                            [
                              _vm.cache.dbSize
                                ? _c("span", [_vm._v(_vm._s(_vm.cache.dbSize))])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "系统架构" } },
                            [
                              _vm.cache.info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cache.info.instantaneous_input_kbps
                                      ) +
                                        "kps/" +
                                        _vm._s(
                                          _vm.cache.info
                                            .instantaneous_output_kbps
                                        ) +
                                        "kps"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "命令统计",
                        bordered: false,
                      },
                    },
                    [
                      _c("div", {
                        ref: "commandstats",
                        staticStyle: { height: "420px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "内存信息",
                        bordered: false,
                      },
                    },
                    [
                      _c("div", {
                        ref: "usedmemory",
                        staticStyle: { height: "420px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }